@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';

.ngx-datatable .datatable-body-cell,
.ngx-datatable .datatable-header-cell {
  overflow-x: initial !important;
  overflow-y: initial !important;
}

.ngx-datatable.material {
  height: 100%;
  box-shadow: none;
  border-top: 1px solid #e5e5dc;
  border-left: none;
  border-right: none;
  border-bottom: none;

  .datatable-header {
    background-color: #fafaf5;
    .datatable-row-left {
      background-color: #fafaf5;
      background-image: none;
      border-right: 1px solid #e5e5dc;
    }
    border-bottom: none;
    .datatable-header-cell {
      padding: 10px;
      font-size: 15px;
      line-height: 18px;
      font-weight: 400;
      color: #808080;
      overflow: hidden;
      text-overflow: ellipsis;

      &.sortable:hover {
        color: #000;
      }
      &.sort-active {
        font-weight: 500;
        color: #000;
      }

      /*
        mat-ripple создает скролл внутри ячейки.
        В table-body это не создаёт проблем потому что скролл самой
        таблицы отрабатывает выше и не даёт скролла
      */
      &.mat-ripple-fix {
        overflow: visible;
      }

      .datatable-header-cell-template-wrap {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        user-select: none;
      }

      .datatable-header-cell-wrapper {
        display: block;
        overflow: hidden;
        flex: 1 1 auto;

        .datatable-header-cell-label {
          display: block;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .datatable-body {
    position: relative;
    .datatable-body-row-wrapper:nth-child(odd) .datatable-body-row {
      background-color: #fbfbfb;
    }
    .datatable-row-left {
      z-index: 9999;
      background-image: none;
      border-right: 1px solid #e5e5dc;
    }
    .datatable-body-row {
      &.active,
      &.active .datatable-row-left {
        background-color: #e5eaf9; // #ccd6f4;
      }
      &:hover .datatable-row-center,
      &:hover .datatable-row-left {
        background-color: #e5eaf9;
      }
      .datatable-body-cell {
        padding: 10px;
        font-size: 15px;
        line-height: 18px;
        font-weight: 400;
        color: #252525;

        &.checkbox-cell .mat-checkbox {
          margin: 0.4375em 0;
          display: block;
        }
      }
    }
  }
  .datatable-body-cell-label {
    height: 100%;
  }
}
