@import './colors';

@font-face {
  font-family: ProbaPro;
  src: url('https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_regular-webfont.eot');
  src: url('https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_regular-webfont.woff2') format('woff2'),
    url('https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_regular-webfont.woff') format('woff'),
    url('https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_regular-webfont.ttf') format('truetype');
  font-weight: 400;
  ont-display: fallback;
}
@font-face {
  font-family: ProbaPro;
  src: url('https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_light-webfont.eot');
  src: url('https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_light-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_light-webfont.woff2') format('woff2'),
    url('https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_light-webfont.woff') format('woff'),
    url('https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_light-webfont.ttf') format('truetype');
  font-display: fallback;
  font-weight: 300;
}
@font-face {
  font-family: ProbaPro;
  src: url('https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_medium-webfont.eot');
  src: url('https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_medium-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_medium-webfont.woff2') format('woff2'),
    url('https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_medium-webfont.woff') format('woff'),
    url('https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_medium-webfont.ttf') format('truetype');
  font-weight: 500;
  font-display: fallback;
}
@font-face {
  font-family: ProbaPro;
  src: url('https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_semibold-webfont.eot');
  src: url('https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_semibold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_semibold-webfont.woff2') format('woff2'),
    url('https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_semibold-webfont.woff') format('woff'),
    url('https://pik.ru.cdn.pik-service.ru/site/fonts/proba_pro_semibold-webfont.ttf') format('truetype');
  font-weight: 600;
  font-display: fallback;
}

html,
body {
  font-family: ProbaPro;
  font-size: 16px;
  color: $black;
  @media all and (max-width: 1440px) {
    font-size: 15px;
  }

  @media all and (max-width: 1280px) {
    font-size: 14px;
  }

  @media all and (max-width: 1024px) {
    font-size: 16px;
  }
}

// Headers
h1,
h2,
h3,
h4 {
  font-weight: bold;
}
h5,
h6 {
  font-weight: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
}
h1 {
  font-size: 3rem;
} // 48 - 45 - 42px
h2 {
  font-size: 2.5rem;
} // 40 - 37.5 - 35px
h3 {
  font-size: 2rem;
} // 32 - 30 - 28px
h4 {
  font-size: 1.5rem;
} // 24 - 22.5 - 21px
h5 {
  font-size: 1.2rem;
} // 19.2 - 18 - 16.8px
h6 {
  font-size: 1rem;
} // 16 - 15 - 14px

.as-link {
  color: $blue;
  cursor: pointer;
  &:hover,
  &:active {
    text-decoration: underline;
  }
}
