@use '@angular/material' as mat;
@import './colors';

@import '~@ng-select/ng-select/themes/material.theme.css';

@include mat.core();

$pik-app-primary: mat.define-palette(mat.$blue-palette, 700, 600, 800);
$pik-app-accent: mat.define-palette(mat.$blue-palette);
$pik-app-warn: mat.define-palette(mat.$red-palette);

$pik-app-theme: mat.define-light-theme($pik-app-primary, $pik-app-accent, $pik-app-warn);

@include mat.all-component-themes($pik-app-theme);

$pik-typography: mat.define-typography-config(
  $font-family: 'ProbaPro, sans-serif',
);

@include mat.all-component-typographies($pik-typography);

.mat-snack-bar-container.mat-snack-bar-center.mat-snack-bar-custom {
  box-shadow: 0 0 12px rgba(34, 34, 34, 0.12);
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 12px 8px;
  font-size: 15px;
  line-height: 16px;
}

.mat-snack-bar-white {
  background-color: $white;
  color: $black;
}

.mat-snack-bar-green {
  background-color: $green-disabled;
  color: $black;
  .mat-icon {
    color: $green;
  }
}

.mat-snack-bar-red {
  background-color: $red-disabled;
  color: $black;
  .mat-icon {
    color: $red;
  }
}

.mat-tooltip {
  font-size: 14px;
}

.mat-custom-icon-button {
  :hover {
    opacity: 0.6;
  }
}

// NgSelect
.ng-select.app-custom-select,
.ng-select.custom-ng-select {
  width: 100%;
  .ng-select-container {
    color: inherit;
    min-height: 2.96875em; // Сумма высоты строки и паддингов в em
    .ng-value-container .ng-input > input {
      font: inherit;
    }
  }
  &.ng-select-multiple {
    .ng-select-container .ng-value-container {
      .ng-value {
        background-color: #4280cc;
        color: #fff;
      }
    }
  }

  .ng-value-container {
    line-height: 1.25;
  }

  &.ng-select-focused .ng-select-container::after {
    border-color: #4280cc;
  }

  &.app-custom-select--required {
    .ng-value-container .ng-placeholder {
      &::after {
        content: ' *';
        color: red;
      }
    }
  }

  &.ng-invalid.ng-dirty .ng-select-container {
    input {
      color: #ef4e23;
    }
    &::after {
      border-color: #ef4e23;
    }
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  color: #4280cc;
}
.ng-select.app-custom-select.ng-select-multiple.ng-select-disabled .ng-select-container .ng-value-container .ng-value {
  padding: 0 5px;
}
.ng-select.app-custom-select.ng-select-multiple:not(.app-custom-select--default-multiple)
  .ng-select-container
  .ng-value-container {
  padding-top: 0.4375em;
  .ng-value {
    padding: 0 0 0 5px;
  }
  .ng-value-icon {
    padding-left: 5px;
  }
  .ng-input {
    line-height: 1;
    input {
      padding: 0;
    }
  }
}
.ng-select.app-custom-select.app-custom-select--inline {
  padding-bottom: 0;

  &.ng-select-multiple {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    .ng-select-container {
      .ng-value-container {
        padding: 0.4375em 0;

        .ng-value {
          visibility: hidden;
          width: 0;
          padding: 0;
          margin: 0;
        }
        .ng-input {
          flex: none;
          line-height: inherit;
          position: absolute;
          left: 0;
          width: 100%;
        }
      }
    }
    .ng-select-container.ng-has-value {
      .ng-clear-wrapper,
      .ng-arrow-wrapper {
        border: none;
      }
    }
  }
  & .ng-select-container {
    min-height: 2.125em;
  }
  & .ng-value-container {
    border: none;
    position: relative;
    &::before {
      content: 'fix-empty-value-height';
      display: inline-block;
      vertical-align: middle;
      width: 0;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .ng-dropdown-panel.ng-select-bottom {
    top: 100%;
  }
  .ng-clear-wrapper {
    text-align: center;
  }
}
.ng-dropdown-panel {
  z-index: 10050 !important;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: pre-wrap !important;
  padding: 10px 16px;
  line-height: 1.4em;
  min-height: 0;
}
