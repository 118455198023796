@import './colors';

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: $light-grey-disabled;
  box-shadow: inset 1px 1px 1px 0 #e3e3e3;
}

::-webkit-scrollbar-thumb {
  background-color: $medium-grey;
  &:hover {
    background-color: $light-grey-hover;
  }
}
