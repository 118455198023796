:root {
  --error-color: #ef4e23;
}

.app-input {
  position: relative;
  display: block;
  box-sizing: border-box;
  padding-bottom: 1.25em;
  cursor: text;
  flex: 1 1 auto;

  &__container {
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    align-items: flex-end;
    outline: 0;
    position: relative;
    width: 100%;

    line-height: 1.25;

    &::after {
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      content: '';
      width: 100%;
      bottom: 0;
      left: 0;
      position: absolute;
      transition: border-color 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    }

    &--invalid {
      color: var(--error-color);

      &::after {
        border-color: var(--error-color);
      }

      input {
        color: var(--error-color);
      }
    }
  }

  &__value {
    display: flex;
    flex: 1;
    align-items: stretch;
    padding: 0.4375em 0;
    border-top: 0.84375em solid transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__placeholder {
    cursor: text;
    position: absolute;
    color: rgba(0, 0, 0, 0.54);
    transform-origin: 0 0;
    user-select: none;
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
      width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__element {
    width: 100%;
    line-height: 0;
    input {
      box-sizing: content-box;
      background: none;
      border: 0;
      padding: 0;
      box-shadow: none;
      outline: 0;
      width: 100%;
      line-height: 1.25;
      font: inherit;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    textarea {
      width: 100%;
      padding: 0;
      border: 0;
      resize: none;
      background-color: transparent;
      font-family: inherit;
      font-size: 15px;
      line-height: 1.25;

      &:disabled {
        background-color: transparent;
      }
    }
  }

  &__clear {
    cursor: pointer;
    position: relative;
    width: 17px;
    user-select: none;
    align-self: flex-end;
    bottom: 0.4375em;
    color: rgba(0, 0, 0, 0.54);
    text-align: center;
  }

  &__hints {
    position: absolute;
    bottom: 0;
  }

  &__required-star {
    color: var(--error-color);
  }

  &__date-hidden {
    visibility: hidden;
    position: absolute;
    bottom: 1.25em;
    left: 0;
    display: block;
    width: 100%;
  }

  &--has-value &__placeholder,
  &--focused &__placeholder {
    transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
    overflow: visible;
  }

  &--disabled &__container {
    color: rgba(0, 0, 0, 0.54);
    &::after {
      border-bottom-color: transparent;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
      background-size: 4px 1px;
      background-repeat: repeat-x;
    }
  }

  &--focused &__container {
    &::after {
      border-width: 2px;
      border-color: #4280cc;
    }
  }

  &--inline &__value {
    border-top-width: 0;
  }

  &--inline {
    padding-bottom: 0;
  }

  &.ng-invalid.ng-dirty &__container {
    input {
      color: var(--error-color);
    }
    &::after {
      border-color: var(--error-color);
    }
  }

  .flatpickr-wrapper {
    display: block;
  }
}

.input-hint {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.75em;
  color: rgba(0, 0, 0, 0.54);

  &--alert {
    color: var(--error-color);
  }
  &--info {
    color: #4280cc;
  }
}

.app-input-suffix {
  &--clickable {
    cursor: pointer;
    &:hover {
      color: #777;
    }
  }
}
