@import './colors';

%btn {
  display: inline-block;
  vertical-align: middle;
  line-height: 1em; // 16px initial
  font-weight: normal;
  font-family: inherit; // remove inherit font style and take global

  max-width: 100%; // don't break container
  border: none; // remove initial <button> border
  overflow: hidden; // very long buttons will cut of
  text-overflow: ellipsis;
  box-sizing: border-box; // work without inherit styles
  user-select: none; // no more selections
  white-space: nowrap;
  text-decoration: none; // remove <a> underline
  outline: none; // remove initial outline for all focusable tags

  @extend %size-normal;

  &:not([disabled]):not(.btn--disabled) {
    cursor: pointer;
  }
}

%size-normal {
  font-size: 0.875em; // 14px from 16px initial
  line-height: 1.71428571428571em;
  padding: 0.571428571428571em 1.14285714285714em; // 8px 16px from 14px fz
  border-radius: 4px;

  min-width: 2.85714285714286em; // 40px for square button from 14px fz
  max-height: 2.85714285714286em; // 40px from 14px fz
  min-height: 2.85714285714286em; // 40px from 14px fz
}

%size-big {
  font-size: 0.875em; // 14px from 16px initial
  line-height: 1.71428571428571em;
  padding: 0.857142857142857em 1.14285714285714em; // 12px 16px from 14px fz
  border-radius: 4px;

  min-width: 3.42857142857143em; // 48px for square button from 14px fz
  max-height: 3.42857142857143em; // 48px from 14px fz
  min-height: 3.42857142857143em; // 48px from 14px fz
}

%size-small {
  font-size: 0.875em; // 14px from 16px initial
  line-height: 1.14285714285714em;
  padding: 0.642857142857143em 1.14285714285714em 0.5em 1.14285714285714em; // 9px 16px 7px 16px from 14px fz
  border-radius: 4px;

  min-width: 2.28571428571429em; // 32px for square button from 14px fz
  max-height: 2.28571428571429em; // 32px from 14px fz
  min-height: 2.28571428571429em; // 32px from 14px fz
}

.btn {
  @extend %btn;

  &--block {
    display: block;
    width: 100%;
  }

  &--big {
    @extend %size-big;
  }

  &--small {
    @extend %size-small;
  }

  @each $color in $color-list {
    &--#{'' + nth($color, 1)} {
      background-color: nth($color, 2);
      color: '' + nth($color, 5);
      &:not([disabled]):not(.btn--disabled) {
        &:hover {
          background-color: '' + nth($color, 3);
          color: nth($color, 6);
        }
        &:focus {
          box-shadow: inset 0 0 0 2px '' + nth($color, 3);
        }
        &:active {
          box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.3);
        }
      }
      &[disabled],
      &.btn--disabled {
        background-color: '' + nth($color, 4);
        color: lighten(nth($color, 7), 50%);
        pointer-events: none; // block any events on css level
      }
    }
  }
}
