.scrollable-dialog {
  height: 100%;
  pointer-events: auto;
  position: static;
  overflow: auto;
  width: 100%;
  display: block;

  mat-dialog-container {
    margin: 10vh auto;
    height: auto;
    max-height: none;
    padding: 0;
    border: none;
    border-radius: 0px;
    overflow: visible;
  }
}
