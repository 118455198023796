.form-dialog {
  mat-dialog-container {
    max-width: 80vw;
    border-left: 8px solid #fa857c;
  }
}

.default-dialog {
  mat-dialog-container {
    max-width: 80vw;
    padding: 0;
    overflow: visible;
  }
}
