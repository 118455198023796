// Vendor
@import './ngx-datatable-custom.scss';
@import 'flatpickr/dist/flatpickr.min.css';
@import './theme.scss';
@import './mat-drag.scss';
@import './mat-bottom-sheet.scss';

// App
@import './core.scss';
@import './typo.scss';
@import './buttons.scss';
@import './scrollable-dialog';
@import './form-dialog';
@import './settings-dialog';
@import './scroll.scss';

// Forms
@import './forms/input.scss';
@import './forms/file-upload.scss';

.mat-progress-bar {
  border-radius: 6px;
}

.mat-progress-bar-buffer {
  border-radius: 6px;
}

.mat-progress-bar-fill::after {
  border-radius: 6px;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1060;
}

.mat-select {
  z-index: 10;
}

.mat-checkbox .mat-checkbox-layout {
  cursor: pointer;
}

.mat-checkbox .mat-checkbox-inner-container {
  height: 16px;
  width: 16px;
}

@media all and (min-width: 1420px) {
  .container {
    width: 100%;
  }
  .modal-lg {
    max-width: 1400px;
  }
}

@media all and (min-width: 1200px) {
  .container {
    width: 100%;
  }
  .modal-lg {
    max-width: 1160px;
  }
}

@media all and (min-width: 992px) {
  .container {
    width: 100%;
  }
}

@media all and (min-width: 768px) {
  .container {
    width: 100%;
  }
}

@media all and (min-width: 576px) {
  .container {
    width: 100%;
  }
}
