.file-upload {
  position: relative;
  padding: 1rem;
  border: 1px solid #e3e3e3;
  border-radius: 4px;

  &__drop-target {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: 10px solid transparent;
    box-shadow: inset 0 0 2px 0px black;
    box-sizing: border-box;
  }

  &__current {
    white-space: nowrap;
    display: inline-block;
    vertical-align: bottom;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0.75em;
  }

  &__preview {
    margin-bottom: 1em;
    user-select: none;
    pointer-events: none;
  }

  &__preview-image {
    max-width: 100%;
    min-width: 60px;
    min-height: 60px;
  }

  &__button {
    position: relative;
    mat-icon,
    span {
      vertical-align: middle;
    }
  }

  &__label {
    margin-bottom: 1em;
  }

  &__input {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: -1;
  }
}
