// Main colors

$black: #1a1a1a;
$black-hover: #5f5f5f;
$black-disabled: #d1d1d1;

$dark-grey: #757575;
$dark-grey-hover: #616161;
$dark-grey-disabled: #e6e6e6;

$medium-grey: #d8d8d8;
$medium-grey-hover: #a9a9a9;
$medium-grey-disabled: #f5f5f5;

$light-grey: #f5f5f5;
$light-grey-hover: #bebebe;
$light-grey-disabled: #f2f2f2;

$blue: #4285f4;
$blue-hover: #386abe;
$blue-disabled: #d9e7fd;

$orange: #ef4e23;
$orange-hover: #ba4121;
$orange-disabled: #fcdcd3;

$yellow: #f4cd42;
$yellow-hover: #bea038;
$yellow-disabled: #fdf5d9;

$green: #81c784;
$green-hover: #679c6a;
$green-disabled: #e6f4e6;

$red: #ef2323;
$red-hover: #ba2121;
$red-disabled: #fcd3d3;

$purple: #d5e4fc;
$purple-hover: #a6b2c4;
$purple-disabled: #f7fafe;

$white: #fff;
$white-hover: #f5f5f5;

// Color list (
//   1:colorName
//   2:primary
//   3:hover/active
//   4:disabled
//   5:textColor
//   6:textHoverColor
//   7:textDisabledColor
// )
// How to use:
//   @each $color in $color-list {
//     &-#{nth($color, 1)} {
//       background-color: nth($color, 2);
//       color: nth($color, 5)
//     }
//   }
$color-list: (
  black $black $black-hover $black-disabled $white $white $black,
  dark-grey $dark-grey $dark-grey-hover $dark-grey-disabled $white $white $black,
  medium-grey $medium-grey $medium-grey-hover $medium-grey-disabled $black $white $black,
  light-grey $light-grey $light-grey-hover $light-grey-disabled $black $white $black,
  blue $blue $blue-hover $blue-disabled $white $white $black,
  orange $orange $orange-hover $orange-disabled $white $white $black,
  yellow $yellow $yellow-hover $yellow-disabled $white $white $black,
  green $green $green-hover $green-disabled $white $white $black,
  red $red $red-hover $red-disabled $white $white $white,
  purple $purple $purple-hover $purple-disabled $black $black $black,
  white $white $white-hover transparent $black $black $black
);

// Overlays
@mixin overlay($opacity: 0.2, $direction: null) {
  @if $direction != null {
    background: linear-gradient(to $direction, transparent 0%, rgba(26, 26, 26, $opacity) 100%);
  } @else {
    background-color: rgba(26, 26, 26, $opacity);
  }
}
